<template>
  <div
    id="app"
    class="container mx-auto">
    <div
      id="nav"
      class="flex space-x-4 mt-2">
      <router-link to="/" class="py-3 px-2 rounded hover:bg-yellow">
        <v-icon icon-name="home-icon"></v-icon>
        <span class="align-top ml-2">Home</span>
      </router-link>
    </div>
    <div class="mx-5 sm:mx-0 mb-16">
      <router-view/>
    </div>
  </div>
</template>

<script>
import VIcon from './components/icons/home-icon.vue'
export default {
  components: {
    VIcon
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Gasolineras.app',
    // all titles will be injected into this template
    titleTemplate: '%s | Gasolineras.app'
  }
}
</script>
