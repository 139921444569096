<template>
  <div>
    <div v-if="fuelStation">
      <div id="map" class="w-100 h-72">
      </div>
      <div class="mt-5 text-right">
        <a :href="mapUrl" class="btn primary" target="_blank">Ver en el mapa </a>
      </div>
      <h2 class="mt-10">
        Gasolinera {{ this.fuelStation.name }} en {{ this.fuelStation.city.name }}.
      </h2>
      <p>
        La gasolinera {{ this.fuelStation.name }} ubicada en la ciudad de {{ this.fuelStation.city.name }},
        provincia de {{this.fuelStation.city.province.name}} cuenta con los
        siguientes precios en los carburantes:
      </p>
      <div class="flex gap-4 my-8">
        <div class="flex-auto bg-blue text-white rounded-md shadow-md p-2">Precio del gasoil: <b>{{lastGasoilPrice}} €</b></div>
        <div class="flex-auto bg-yellow rounded-md shadow-md p-2">Precio de la gasolina: <b>{{lastGasolinePrice}} €</b></div>
      </div>
      <div class="block sm:flex pt-5">
        <section class="flex-1">
          <h3>Evolución del precio</h3>
          <apex-chart type="line" :options="chartOptions" :series="series" ></apex-chart>
        </section>
        <section class="flex-1">
          <h3>Ahorro</h3>
          <p>
            La gasolinera <b>{{fuelStation.name}}</b> cuenta con un precio para el diesel de <b>{{lastGasoilPrice}}</b>
            , y de <b>{{lastGasolinePrice}}</b> en el caso de la gasolina. <br>
            <span v-if="bestPrice">
              Esto supone  un {{percentValue.toFixed(2)}}% en la diferencia de precio en
             el caso de {{priceSelector}} con respecto al precio de las gasolineras de su entorno,
             lo que supondría un importe de <b>{{depositPrice.toFixed(2)}} de sobrecoste en un depósito de 60 litros</b>.
            </span>
            <span v-else>
              <router-link
                to="/"
                class="text-blue-700"
              >Realice una búsqueda</router-link> para conocer el ahorro con respecto a las gasolineras de su entorno.
            </span>
          </p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import ApexChart from 'vue-apexcharts'
import { Loader } from '@googlemaps/js-api-loader'
import { mapActions, mapState } from 'vuex'

const loader = new Loader({
  apiKey: 'AIzaSyC-np4ck7E4YGCcmOzb42CIYpV5JJD_ZdU'
  // version: "weekly",
  // ...additionalOptions,
})
export default {
  async mounted () {
    this.getFuelStation(this.$route.params.id).then(result => {
      this.fuelStation = result.data.data.fuelStation
      loader.load().then(() => {
        this.map = new window.google.maps.Map(document.getElementById('map'), {
          center: {
            lat: this.fuelStation.position.coordinates[1],
            lng: this.fuelStation.position.coordinates[0]
          },
          zoom: 15,
          mapId: '43ef0c965a6b433b'
        })
        const image = {
          url: require('../assets/logo.svg'),
          scaledSize: new window.google.maps.Size(32, 32)
        }
        // The marker, positioned at Fuelstation
        this.marker = new window.google.maps.Marker({
          position: {
            lat: this.fuelStation.position.coordinates[1],
            lng: this.fuelStation.position.coordinates[0]
          },
          map: this.map,
          icon: image
        })
      })
      // Chart data map
      const gasolinePrices = []
      const gasoilPrices = []
      const dates = []
      this.fuelStation.fuelpriceSet.edges.forEach(element => {
        gasolinePrices.push(element.node.gasolinePrice)
        gasoilPrices.push(element.node.gasOilPrice)
        dates.push(element.node.date)
      })
      this.series.push({ name: 'Gasolina', data: gasolinePrices })
      this.series.push({ name: 'Gasoil', data: gasoilPrices })
      this.chartOptions.xaxis.categories = dates
      this.chartOptions.yaxis.min = this.graphMinValue
    })
  },
  components: {
    ApexChart
  },
  metaInfo () {
    return {
      title: `Estación de servicio ${this.fuelStation ? this.fuelStation.name : ''}`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: `Precio de los carburantes para la gasolinera ${this.fuelStation ? this.fuelStation.name : ''} hoy ubicada en ${this.fuelStation ? this.fuelStation.city.name : ''}`
        }
      ]
    }
  },
  data () {
    return {
      isLoading: false,
      fuelStation: null,
      chartOptions: {
        colors: ['#fca311', '#14213d'],
        chart: {
          id: 'fuel',
          toolbar: { show: false }
        },
        xaxis: {
          lines: {
            show: false
          },
          categories: []
        },
        yaxis: {
          min: 1.20,
          show: true
        }
      },
      series: [],
      stroke: {
        curve: 'smooth'
      }
    }
  },
  methods: {
    ...mapActions([
      'getFuelStation'
    ])
  },
  computed: {
    ...mapState(['priceSelector', 'bestPrice']),
    isMobile: function () {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    },
    mapUrl: function () {
      return this.isMobile ? `geo:${this.fuelStation.position.coordinates[1]}, ${this.fuelStation.position.coordinates[0]}`
        : `https://maps.google.com/?q=${this.fuelStation.position.coordinates[1]},${this.fuelStation.position.coordinates[0]}`
    },
    lastGasolinePrice: function () {
      return this.fuelStation.fuelpriceSet.edges.at(-1).node.gasolinePrice
    },
    lastGasoilPrice: function () {
      return this.fuelStation.fuelpriceSet.edges.at(-1).node.gasOilPrice
    },
    selectedPrice: function () {
      if (this.priceSelector === 'gasolina') {
        return this.lastGasolinePrice
      }
      return this.lastGasoilPrice
    },
    percentValue: function () {
      return this.selectedPrice * 100 / this.bestPrice - 100
    },
    depositPrice: function () {
      const best = this.bestPrice * 60
      const current = this.selectedPrice * 60
      return best - current
    },
    graphMinValue: function () {
      const minGasoline = Math.min(...this.series[0].data)
      const minGasoil = Math.min(...this.series[1].data)
      console.log(Math.min(minGasoil, minGasoline) - 0.20)
      return Math.min(minGasoil, minGasoline) - 0.10
    }
  }
}
</script>
