<template>
    <button type="button" class="btn primary" @click="$emit('click')" :disabled="isLoading">
        <loading-animation :isLoading="isLoading"></loading-animation>
        <slot></slot>
    </button>
</template>

<script>
import LoadingAnimation from '../components/loading-animation.vue'
export default {
  props: {
    isLoading: Boolean
  },
  components: {
    LoadingAnimation
  }
}
</script>

<style scoped>
button:disabled {
    @apply opacity-50 cursor-not-allowed;
}
</style>
