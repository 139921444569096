import { util } from 'vue'

export default {
  data () {
    return {
      items: [],
      current: -1,
      loading: false,
      selectFirst: false
    }
  },

  computed: {
    hasItems () {
      return this.items.length > 0
    },

    isEmpty () {
      return !this.searchText
    },

    isDirty () {
      return !!this.searchText
    }
  },

  methods: {
    update () {
      this.cancel()

      if (!this.searchText) {
        return this.reset()
      }

      if (this.minChars && this.searchText.length < this.minChars) {
        return
      }

      this.loading = true

      this.fetch().then((response) => {
        if (response && this.searchText) {
          let data = response.data
          data = this.prepareResponseData ? this.prepareResponseData(data) : data
          this.items = this.limit ? data.slice(0, this.limit) : data
          this.current = -1
          this.loading = false

          if (this.selectFirst) {
            this.down()
          }
        }
      })
    },

    fetch () {
      if (!this.$http) {
        return util.warn('You need to provide a HTTP client', this)
      }

      if (!this.src) {
        return util.warn('You need to set the `src` property', this)
      }

      const cancel = new Promise(resolve => {
        this.cancel = resolve
      })
      const request = this.$http.post(this.src, this.dataToSend)

      return Promise.race([cancel, request])
    },

    cancel () {
      // used to 'cancel' previous searches
    },

    reset () {
      this.items = []
      this.searchText = ''
      this.loading = false
    },

    setActive (index) {
      this.current = index
    },

    activeClass (index) {
      return {
        active: this.current === index
      }
    },

    hit () {
      if (this.current !== -1) {
        this.onHit(this.items[this.current])
      }
    },

    up () {
      if (this.current > 0) {
        this.current--
      } else if (this.current === -1) {
        this.current = this.items.length - 1
      } else {
        this.current = -1
      }
    },

    down () {
      if (this.current < this.items.length - 1) {
        this.current++
      } else {
        this.current = -1
      }
    },

    onHit () {
      util.warn('You need to implement the `onHit` method', this)
    }
  }
}
