const HOST = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/' : 'https://api.rocketcity.es/'
const API_BASE = `${HOST}back/graphql/`

const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 1000 * 60 * 2
    })
  })
}

export {
  getUserLocation,
  API_BASE
}
