<template>
    <div class="relative">
        <a
         @click="showDialog"
        >
            <v-icon :size="32"></v-icon>
        </a>
        <div tabindex="1" class="card" v-show="show" @blur="onBlur" ref="input2">
          <h3>Mostrar precios de:</h3>
          <hr>
          <div class="input-group">
            <input type="radio" id="gasolina" name="priceSelector" value="gasolina" v-model="priceSelector"/>
            <label for="gasolina"> Gasolina</label>
          </div>
          <div class="input-group">
            <input type="radio" id="gasoil" name="priceSelector" value="gasoil" v-model="priceSelector"/>
          <label for="gasoil"> Gasoil</label>
          </div>
          <h3 class="mt-4">Ordenar por:</h3>
          <hr>
          <div class="input-group">
            <input type="radio" id="precio" name="orderSelector" value="precio" v-model="orderSelector"/>
            <label for="precio"> Precio</label>
          </div>
          <div class="input-group" v-if="userPosition">
            <input type="radio" id="distancia" name="orderSelector" value="distancia" v-model="orderSelector"/>
            <label for="distancia"> Distancia</label>
          </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import VIcon from '../components/icons/settings-icon.vue'

export default {
  components: {
    VIcon
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    showDialog: function () {
      this.show = true
      this.$refs.input2.focus()
    },
    onBlur: function (event) {
      this.show = false
    },
    ...mapActions([
      'getBestPrice',
      'orderFuelStationsResult'
    ])
  },
  computed: {
    priceSelector: {
      get () {
        return this.$store.state.priceSelector
      },
      set (value) {
        this.$store.commit('setPriceSelector', value)
        this.getBestPrice()
        this.orderFuelStationsResult()
      }
    },
    orderSelector: {
      get () {
        return this.$store.state.orderSelector
      },
      set (value) {
        this.$store.commit('setOrderSelector', value)
        this.orderFuelStationsResult()
      }
    },
    ...mapState([
      'userPosition'
    ])
  }
}
</script>
<style>
  .card {
    @apply absolute bg-white right-1 rounded p-5 w-max shadow-md;
  }
  .card label {
    @apply text-sm;
  }
  .input-group {
    @apply my-2;
  }
  input[type=radio] {
    @apply h-3 w-3 appearance-none rounded-full focus:ring-2 ring-black;
  }
  input[type=radio] {
    @apply  bg-gray-300;
  }
  input[type=radio]:checked {
    @apply  bg-yellow;
  }
</style>
