<template>
    <div>
      <div class="flex justify-between">
        <h1>Gasolineras cercanas</h1>
      <fuel-selector></fuel-selector>
      </div>
        <div class="flex flex-wrap mt-7 gap-4 justify-center">
          <station-card class="flex-initial w-80" :station="station" :key="station.node.id" v-for="station in fuelStations"></station-card>
        </div>
        <div v-if="locationError">
          <h2 class="text-blue">No ha sido posible obtener su localización</h2>
          <p>Su localización nos ayuda a ofrecerle las gasolineras más cercanas. <br>
          Le recomendamos activarla para una mejor experiencia. No almacenamos ni
          compartimos ningún dato de navegación. <router-link class="no-underline hover:underline" to="/about">Más info.</router-link></p>
        </div>
        <diagonal-container>
          <h1 class="text-white">
            Gasolineras cercanas a usted.
          </h1>
          <p>
            En el anterior listando, le mostramos las gasolineras más cercanas a usted,
             ordenadas por <b>{{ orderSelector }}</b>. <br>
             Los precios que se muestran, son
             precios de <b>{{ priceSelector }}</b>.
          </p>
          <button @click="$router.push(`gasolinera/${lowCostFuelStations[0].node.id}`)" class="btn primary mt-2">Ver la gasolinera más barata</button>
        </diagonal-container>
    </div>
</template>

<style>
.card-icon {
  transform: rotate(50deg);
}
</style>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import StationCard from '../components/station-card.vue'
import FuelSelector from '../components/fuel-selector.vue'
import diagonalContainer from '../components/diagonal-container.vue'

export default {
  components: {
    StationCard,
    FuelSelector,
    diagonalContainer
  },
  metaInfo: {
    title: 'Gasolineras cercanas',
    meta: [
      { name: 'description', content: 'Gasolineras cercanas a usted, y su precios actualizados a hoy.' }
    ]
  },
  data: () => {
    return {
      locationError: false
    }
  },
  computed: {
    ...mapState(
      ['userPosition', 'fuelStations', 'bestPrice', 'priceSelector', 'orderSelector']
    ),
    ...mapGetters(['lowCostFuelStations'])
  },
  methods: {
    ...mapActions(
      ['getFuelStations', 'getUserPosition']
    )
  },
  mounted: function () {
    if (!this.userPosition) {
      this.getUserPosition().then((result) => {
        this.getFuelStations()
      }).catch(error => {
        this.locationError = error
      })
    } else {
      this.getFuelStations()
    }
  }
}
</script>
