<template>
  <router-link :to="`/gasolinera/${station.node.id}`" class="bg-yellow shadow-md rounded p-2 md:py-5">
    <h2 class="text-blue">{{ station.node.name }}</h2>
    <p class="text-sm float-left" v-if="station.node.distance">
      <pin-icon
        name="pin-outline"
        height="20"
        class="card-icon"></pin-icon>
      {{ station.node.distance }} kms
    </p>
    <p class="text-sm float-right text-blue">
      <award-icon
        v-if="price === bestPrice"
        name="award-outline"
        height="20"
        fill="#b11f0b"
        class="card-icon animate-bounce"></award-icon>
      <b>
        {{ price }} €
      </b>
    </p>
  </router-link>
</template>

<script>
import { mapState } from 'vuex'
import awardIcon from './icons/award-icon.vue'
import PinIcon from './icons/pin-icon.vue'
export default {
  props: {
    station: Object
  },
  components: {
    awardIcon,
    PinIcon
  },
  computed: {
    ...mapState([
      'bestPrice',
      'priceSelector'
    ]),
    price () {
      if (this.priceSelector === 'gasolina') {
        const price = this.station.node.fuelpriceSet.edges[0].node.gasolinePrice
        return price !== 0 ? price : '--'
      } else {
        const price = this.station.node.fuelpriceSet.edges[0].node.gasOilPrice
        return price !== 0 ? price : '--'
      }
    }
  }
}
</script>
