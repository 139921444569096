import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { getUserLocation, API_BASE } from '../utils'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userPosition: null,
    fuelStations: null,
    cityName: null,
    bestPrice: null,
    badPrice: null,
    priceSelector: 'gasolina',
    orderSelector: 'distancia'
  },
  mutations: {
    setUserPosition (state, position) {
      state.userPosition = position
    },
    setFuelStations (state, stations) {
      state.fuelStations = stations
    },
    setCityName (state, value) {
      state.cityName = value
    },
    setBestPrice (state, value) {
      state.bestPrice = value
    },
    setBadPrice (state, value) {
      state.badPrice = value
    },
    setPriceSelector (state, value) {
      state.priceSelector = value
    },
    setOrderSelector (state, value) {
      state.orderSelector = value
    }
  },
  getters: {
    lowCostFuelStations (state) {
      if (state.priceSelector === 'gasolina') {
        return state.fuelStations.filter(
          fuelStation => fuelStation.node.fuelpriceSet.edges[0].node.gasolinePrice === state.bestPrice
        )
      } else {
        return state.fuelStations.filter(
          fuelStation => fuelStation.node.fuelpriceSet.edges[0].node.gasOilPrice === state.bestPrice
        )
      }
    }
  },
  actions: {
    async getUserPosition ({ commit }) {
      const result = await getUserLocation()
      commit('setUserPosition', { latitude: result.coords.latitude, longitude: result.coords.longitude })
    },
    getFuelStations ({ commit, state, dispatch }) {
      const { userPosition } = state
      if (userPosition) {
        axios.post(API_BASE, {
          // eslint-disable-next-line
          query: `{allFuelStations(position: "{\\\"latitude\\\": ${userPosition.latitude}, \\\"longitude\\\": ${userPosition.longitude}}" first: 9) {
              edges {
                node {
                  id
                  name
                  distance
                  fuelpriceSet(orderBy: "date" last: 1) {
                    edges {
                      node {
                        gasolinePrice
                        gasOilPrice
                      }
                    }
                  }
                }
              }
            }
          }`
        }).then(result => {
          const fuelStations = result.data.data.allFuelStations.edges
          commit('setFuelStations', fuelStations)
          dispatch('getBestPrice')
          dispatch('orderFuelStationsResult')
        })
      }
    },
    async getCityStations ({ commit, state, dispatch }, payload) {
      const { url } = payload
      const { userPosition } = state
      const stationSearch = userPosition
        // eslint-disable-next-line
        ? `(position: "{\\\"latitude\\\": ${userPosition.latitude}, \\\"longitude\\\": ${userPosition.longitude}}")`
        : ''
      const result = await axios.post(API_BASE, {
        query: `{
          allCities(url: "${url}") {
            edges {
              node {
                name
                fuelstationSet${stationSearch} {
                  edges {
                    node {
                      id
                      name
                      distance
                      fuelpriceSet(orderBy: "date" last: 1) {
                        edges {
                          node {
                            gasolinePrice
                            gasOilPrice
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`
      })
      const fuelStations = result.data.data.allCities.edges[0]
        .node.fuelstationSet.edges
      commit('setFuelStations', fuelStations)
      commit('setCityName', result.data.data.allCities.edges[0]
        .node.name)
      dispatch('getBestPrice')
      dispatch('orderFuelStationsResult')
    },
    async getFuelStation ({ state }, id) {
      return await axios.post(API_BASE, {
        query: `{
          fuelStation(id: "${id}") {
            id
            name
            position
            fuelpriceSet(orderBy: "date" last: 7) {
              edges {
                node {
                  gasOilPrice
                  gasolinePrice
                  date
                }
              }
            }
            city {
              name
              province {
                name
              }
            }
          }
        }`
      })
    },
    async getBestPrice ({ commit, state }) {
      const { fuelStations, priceSelector } = state
      if (priceSelector === 'gasolina') {
        const fs = [...fuelStations].filter((value) => value.node.fuelpriceSet.edges[0].node.gasolinePrice !== 0)
        fs.sort((prev, current) => {
          return prev.node.fuelpriceSet.edges[0].node.gasolinePrice -
            current.node.fuelpriceSet.edges[0].node.gasolinePrice
        })
        commit('setBestPrice', fs[0].node.fuelpriceSet.edges[0].node.gasolinePrice)
        commit('setBadPrice', fs[fs.length - 1].node.fuelpriceSet.edges[0].node.gasolinePrice)
      } else {
        const fs = [...fuelStations].filter((value) => value.node.fuelpriceSet.edges[0].node.gasOilPrice !== 0)
        fs.sort((prev, current) => {
          return prev.node.fuelpriceSet.edges[0].node.gasOilPrice -
            current.node.fuelpriceSet.edges[0].node.gasOilPrice
        })
        commit('setBestPrice', fs[0].node.fuelpriceSet.edges[0].node.gasOilPrice)
        commit('setBadPrice', fs[fs.length - 1].node.fuelpriceSet.edges[0].node.gasOilPrice)
      }
    },
    async orderFuelStationsResult ({ state }) {
      const { orderSelector, fuelStations, priceSelector } = state
      if (orderSelector === 'distancia') {
        fuelStations.sort(
          (prev, current) => {
            return prev.node.distance -
            current.node.distance
          }
        )
      } else {
        if (priceSelector === 'gasolina') {
          fuelStations.sort(
            (prev, current) => {
              return prev.node.fuelpriceSet.edges[0].node.gasolinePrice -
              current.node.fuelpriceSet.edges[0].node.gasolinePrice
            }
          )
        } else {
          fuelStations.sort(
            (prev, current) => {
              return prev.node.fuelpriceSet.edges[0].node.gasOilPrice -
              current.node.fuelpriceSet.edges[0].node.gasOilPrice
            }
          )
        }
      }
    }
  },
  modules: {
  }
})
