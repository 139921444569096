<template>
  <div class="text-center mt-20 max-w-md md:mx-auto mx-5">
    <h1 class="text-yellow">Gasolineras.app</h1>
    <p>Encuentra tu <b>gasolinera más cercana</b>.</p>
    <p>Conoce el precio que tiene el combustible ahora.</p>
    <p class="">Y no sólo eso, conoce la distancia de la gasolinera, su horario, y cual es la más barata de tu ciudad.</p>
    <v-button v-if="!manualSearch" class="mt-10" :isLoading="isLoading" @click="search">
      Encontrar gasolinera
    </v-button>
    <div v-else class="mt-10">
      <div class="w-full px-3 text-left">
        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="city-imput">
          Ciudad
        </label>
        <input class="appearance-none block w-full text-gray-700 border border-gray-200 rounded
          py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="city-imput"
          type="text"
          placeholder="Madrid"
          autocomplete="off"
          v-model="searchText"
          @keydown.down="down"
          @keydown.up="up"
          @keydown.enter="hit"
          @keydown.esc="reset"
          @blur="reset"
          @input="update">
          <!-- the list -->
        <ul v-show="hasItems" class="items">
          <!-- for vue@1.0 use: ($item, item) -->
          <li
            v-for="(item, $item) in items" :class="activeClass($item)"
            :key="item.name"
            @mousedown="hit"
            @mousemove="setActive($item)">
            <router-link to="" class="item">
              <div class="flex-1" v-text="item.node.name"></div>
              <div class="flex-1 text-right text-gray-500 text-xs italica">({{ item.node.province.name }})</div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import VButton from '../components/v-button.vue'
import { API_BASE } from '../utils'
import VueTypeahead from '../components/type-ahead'

export default {
  extends: VueTypeahead,
  name: 'Home',
  metaInfo: {
    title: 'Búsqueda',
    meta: [
      { name: 'description', content: 'Busca las gasolineras cercanas a usted, y consulta el precio de los carburantes actualizados a su precio hoy.' }
    ]
  },
  components: {
    VButton
  },
  data: function () {
    return {
      searchText: '',
      isLoading: false,
      manualSearch: false,
      // The source url
      // (required)
      src: API_BASE,

      // The minimum character length needed before triggering
      // (optional)
      minChars: 3
    }
  },
  methods: {
    search: function (event) {
      this.isLoading = true
      this.getUserPosition()
        .then(result => {
          this.manualSearch = true
          this.$router.push('/cercanas')
        })
        .catch(error => {
          // show manual search
          this.isLoading = false
          this.manualSearch = true
          console.log(error)
        })
    },
    // The callback function which is triggered when the user hits on an item
    // (required)
    onHit (item) {
      this.$router.push(`/${item.node.url}`)
    },

    // The callback function which is triggered when the response data are received
    // (optional)
    prepareResponseData (data) {
      return data.data.allCities.edges
    },
    ...mapActions([
      'getUserPosition'
    ])
  },
  computed: {
    dataToSend: function () {
      return {
        query: `{
            allCities(name: "${this.searchText}") {
              edges {
                node {
                  id
                  name
                  url
                  province {
                    name
                  }
                }
              }
            }
          }`
      }
    },
    ...mapState([
      'userPosition'
    ])
  },
  watch: {
    manualSearch: function (value) {
      this.isLoading = false
    }
  }
}
</script>
<style>
.items {
  @apply bg-white rounded mt-2;
}
.item {
  @apply p-5 flex;
}
</style>
