<template>
  <div>
    <div v-if="loading">
      <loading-animation :isLoading="true"></loading-animation>
      Cargando datos...
    </div>
    <div v-if="!loading">
      <div class="flex justify-between">
        <h1>Gasolineras disponibles en {{ cityName }}</h1>
        <fuel-selector></fuel-selector>
      </div>
      <p v-if="!userPosition">Habilite la geolocalización para una mejor experiencia. No almacenamos ni
          compartimos ningún dato de navegación. <router-link class="no-underline hover:underline" to="/about">Más info.</router-link></p>
      <div class="flex flex-wrap mt-7 gap-4 justify-center">
        <station-card class="flex-initial w-80" :station="station" :key="station.node.id" v-for="station in fuelStations"></station-card>
      </div>
    </div>
    <diagonal-container>
      <h1 class="text-white">
        Gasolineras en {{ cityName }}.
      </h1>
      <p>
        En el anterior listando, le mostramos las gasolineras disponibles en {{ cityName }},
          ordenadas por <b>{{ orderSelector }}</b>. <br>
          Los precios que se muestran, son
          precios de <b>{{ priceSelector }}</b>.
      </p>
      <button @click="$router.push(`../gasolinera/${lowCostFuelStations[0].node.id}`)" class="btn primary mt-2">Ver la gasolinera más barata</button>
    </diagonal-container>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import StationCard from '../components/station-card.vue'
import LoadingAnimation from '../components/loading-animation.vue'
import FuelSelector from '../components/fuel-selector.vue'
import DiagonalContainer from '../components/diagonal-container.vue'

export default {
  components: {
    StationCard,
    LoadingAnimation,
    FuelSelector,
    DiagonalContainer
  },
  metaInfo () {
    return {
      title: `Gasolineras en ${this.$route.params.city}`,
      meta: [{
        vmid: 'description',
        name: 'description',
        content: `Precios en todas las gasolineras disponibles
          en ${this.$route.params.city}.`
      }]
    }
  },
  data () {
    return {
      loading: false,
      locationError: null
    }
  },
  computed: {
    ...mapState([
      'userPosition',
      'fuelStations',
      'cityName',
      'orderSelector',
      'priceSelector'
    ]),
    ...mapGetters([
      'lowCostFuelStations'
    ])
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.getStations({
          url: `${this.$route.params.province}/${this.$route.params.city}`
        })
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  },
  methods: {
    getStations: function (payload) {
      this.loading = true
      if (!this.userPosition) {
        this.getUserPosition()
          .then((result) => {
            this.localGetStations(payload)
          })
          .catch(error => {
            this.locationError = error
            this.localGetStations(payload)
          })
      } else {
        this.localGetStations(payload)
      }
    },
    localGetStations: function (payload) {
      this.getCityStations(payload)
        .then(result => {
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    ...mapActions([
      'getCityStations',
      'getUserPosition'
    ])
  }
}
</script>
