<template>
  <svg v-if="isLoading" class="animate-spin h-5 w-5 mr-3 fill-current inline" viewBox="0 0 24 24">
    <circle cx="8" cy="12" r="3"/>
    <circle cx="16" cy="12" r="3"/>
  </svg>
</template>

<script>
export default {
  props: {
    isLoading: Boolean
  }
}
</script>
