import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import ShowStations from '../views/ShowStations.vue'
import ShowCityStations from '../views/ShowCityStations.vue'
import FuelStation from '../views/FuelStation.vue'
const About = () => import('../views/About.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/cercanas',
    name: 'Cercanas',
    component: ShowStations
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: About
  },
  {
    path: '/gasolinera/:id',
    name: 'Gasolinera',
    component: FuelStation
  },
  {
    path: '/:province/:city',
    name: 'Ciudad',
    component: ShowCityStations
  },
  {
    // old system redirection
    path: '/gasolineras/:province/:city',
    redirect: { name: 'Ciudad' }
  },
  {
    // old system redirection
    path: '/gasolineras/:province/:city/gasolinera/:id',
    redirect: to => {
      const id = to.params.id
      const encodedId = window.btoa(`FuelStationNode:${id}`)
      const epath = `/gasolinera/${encodedId}=`
      return { path: epath }
    }
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
