<template>
    <svg xmlns="http://www.w3.org/2000/svg"
        :width="size"
        :height="size"
        class="inline"
        viewBox="0 0 24 24"><g data-name="Layer 2"><g data-name="award"><rect width="24" height="24" opacity="0"/><path d="M19 20.75l-2.31-9A5.94 5.94 0 0 0 18 8 6 6 0 0 0 6 8a5.94 5.94 0 0 0 1.34 3.77L5 20.75a1 1 0 0 0 1.48 1.11l5.33-3.13 5.68 3.14A.91.91 0 0 0 18 22a1 1 0 0 0 1-1.25zM12 4a4 4 0 1 1-4 4 4 4 0 0 1 4-4zm.31 12.71a1 1 0 0 0-1 0l-3.75 2.2L9 13.21a5.94 5.94 0 0 0 5.92 0L16.45 19z"/></g></g></svg></template>
<script>
export default {
  props: {
    size: {
      type: Number,
      default: 24
    }
  }
}
</script>
